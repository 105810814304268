.page-template-page-catering {
	.pageContent {
		padding-top: rem(30);
		padding-bottom: rem(50);
		svg {
			width: 100%;
			height: auto;

			a {
				&:hover {
					path {
						fill: white;
					}
					circle {
						stroke: white;
					}
				}
			}
		}
	}

	.glide .glide__slides {
		border-top: 0;
	}

	.form--modal {
		z-index: 999999;
		justify-content: flex-start;

		.gform_confirmation_wrapper {
			margin-top: rem(60);

			@include min(md) {
				margin: rem(60) auto;
				max-width: rem(600);
			}
		}

		form {
			margin-bottom: 2.5rem;

			@include min(md) {
				margin: rem(60) auto;
				max-width: rem(600);
			}
		}
	}

	textarea {
		color: black;
	}
}

.cateringMenu {
	background-color: color(black);
	text-align: center;
	padding-left: rem(30);
	padding-right: rem(30);

	a {
		background-color: color(green);
		display: block;
		color: black;
		padding-top: rem(12);
		padding-bottom: rem(7);
		text-decoration: none;
		font-size: rem(24);
		margin: 0 auto rem(50);
		border-radius: rem(10);
		width: 100%;
		max-width: 1000px;

		@include min(md) {
			border-radius: rem(20);
			font-size: rem(30);
			padding-top: rem(30);
			padding-bottom: rem(30);
			margin: rem(50) auto rem(100);
		}

		@include min(lg) {
			font-size: rem(40);
		}

		&:hover,
		&:focus {
			color: white;
			background-color: color(green--dark);
		}
	}
}

.cateringForm {
	.gform_wrapper {
		input {
			width: 100% !important;
		}
	}
}

.cateringClose {
	position: fixed;
	top: 1em;
	right: 1em;
	transform: none;
}

// Catering Modal

.cateringModal__content {
	background: color(green);
	padding: 2em;
	max-width: 700px;
	color: color(black);
	position: relative;
}

.cateringModal {
	.cateringModalClose {
		transform: none;

		@include min(md) {
			transform: translate(30%, -30%);
		}
	}
}
