/* Responsive Videos */

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

img,
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
	max-width: 100%;
	height: auto;
}

svg.full {
	width: 100%;
	height: auto;
}

/**** 768 AND UP ****/

@include min(sm) {
	/* at this larger size, we can start to align images */
	.alignleft,
	img.alignleft {
		margin-right: 1.5em;
		display: inline;
	}
	.alignright,
	img.alignright {
		margin-left: 1.5em;
		display: inline;
	}
	.aligncenter,
	img.aligncenter {
		margin-right: auto;
		margin-left: auto;
		display: block;
		clear: both;
	}
}
