/******************************************************************
Button Styles
******************************************************************/

/// BASE BUTTON
/// @example
///     @include buton($color);
/// @param {variable} $btn-color [$link-color] - Background Button Color

@mixin button($btn-color: color(green--dark), $btn-font: color(white)) {
	display: inline-block;
	position: relative;
	font-family: $sans-serif;
	text-decoration: none;
	color: $btn-font !important;
	font-size: rem(24);
	line-height: 34px;
	font-weight: normal;
	padding: 0.5em 1em;
	border: 0;
	text-transform: uppercase;
	cursor: pointer;
	transition: background-color 0.14s ease-in-out;
	background: $btn-color;
	box-shadow: rem(-10) rem(14) rem(14) rgba(0, 0, 0, 0.25);

	@include min(md) {
		font-size: rem(33);
	}

	// hovering on the btn
	&:hover,
	&:focus {
		color: white;
		text-decoration: none;
		background: darken($btn-color, 15%);
		box-shadow: rem(-2) rem(2) rem(14) rgba(0, 0, 0, 0.25);
	} // end hover
} // end .button

// Regular Button
.button {
	@include button();
}

// Add focus to button element
button:focus {
	outline: 3px dotted gray;
}
