/******************************************************************
Typography Styles
******************************************************************/

@font-face {
	font-family: "spicy_mexican_street_cornn_Rg";
	src: url("/wp-content/themes/tacoria/public/fonts/spicymexicanstreetcornn-regular-webfont.woff2")
			format("woff2"),
		url("/wp-content/themes/tacoria/public/fonts/spicymexicanstreetcornn-regular-webfont.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "tacoria_regular";
	src: url("/wp-content/themes/tacoria/public/fonts/tacoria-regular-webfont.woff2")
			format("woff2"),
		url("/wp-content/themes/tacoria/public/fonts/tacoria-regular-webfont.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

blockquote {
	font-size: rem(16);
	line-height: 1.25;
	/* 25px / 20px */
	border-left: 4px solid gray;
	padding-left: 1em;

	@include min(md) {
		font-size: rem(24);
		line-height: 1.45833333;
	}
}

figure,
figcaption {
	display: block;
	font-style: italic;
	font-size: 0.9em;
	color: gray;
	text-align: center;
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	text-rendering: optimizelegibility;
	font-weight: 500;

	a {
		text-decoration: none;
	}
}

h1,
.h1 {
	font-size: rem(32);
	line-height: 1.25;

	@include min(md) {
		font-size: rem(40);
		line-height: 1.125;
	}

	@include min(lg) {
		font-size: rem(48);
		line-height: 1.05;
	}
}

h2,
.h2 {
	font-size: rem(26);
	line-height: 1.15384615;
	margin-bottom: 0.375em;
	font-family: $serif;

	@include min(md) {
		font-size: rem(32);
		line-height: 1.25;
	}

	@include min(lg) {
		font-size: rem(36);
		line-height: 1.25;
	}
}

h3,
.h3 {
	font-size: rem(22);
	line-height: 1.13636364;

	@include min(md) {
		font-size: rem(24);
		line-height: 1.25;
	}

	@include min(lg) {
		font-size: rem(28);
		line-height: 1.25;
	}
}

h4,
.h4 {
	color: $link-color;
	font-size: rem(18);
	line-height: 1.11111111;

	@include min(md) {
		line-height: 1.222222222;
	}
}

h5,
.h5 {
	font-size: rem(13.5);
	line-height: 1.25;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: $link-color;
}

h6,
.h6 {
	color: $link-color;
}

.alert {
	display: inline-block;
	font-size: 0.9em;
	background-color: color(black);
	color: color(green);
}
