.eat-main {
	svg {
		width: 100%;
		height: auto;

		a {
			&:hover {
				path {
					fill: white;
				}
			}
		}
	}
}

.glide {
	img {
		width: 100%;
		height: auto;
		display: block;
	}

	.glide__arrow {
		border: none;
		box-shadow: none;
	}

	.glide__slides {
		border-top: 6px solid color(green);
		border-bottom: 6px solid color(green);
		margin: 0;
	}
}
