.contactForm {
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: 0.2s all ease-in-out;
	z-index: -1;
	padding-bottom: rem(40);
	background: rgba(0, 0, 0, 0.8);
	overflow: auto;

	&.visible {
		opacity: 1;
		pointer-events: auto;
		visibility: visible;
		z-index: 100001;

		form {
			opacity: 1;
			transform: translateY(0);
			transition-delay: 0.2s;
		}
	}

	.form__container {
		position: relative;
		z-index: 2;
	}

	textarea {
		height: 120px;
		color: black;
	}

	form {
		opacity: 0;
		transform: translateY(rem(100));
		background: color(green);
		padding: rem(30);
		width: 90%;
		max-width: rem(500) !important;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		color: black;
		text-transform: uppercase;
		font-family: $sans-serif;
		text-align: left;
		transition: 0.2s all ease-in-out;
		margin-top: rem(40);

		@include min(md) {
			min-width: rem(400);
		}
	}

	label {
		margin-top: rem(20);
	}

	p {
		margin: 0;
	}

	input {
		border: none;
		border-bottom: 4px dotted black;
	}

	input[type="submit"] {
		border-bottom: none;
		box-shadow: none;
		font-size: rem(24);
		background-color: black;
	}

	select,
	input {
		color: black;
		text-transform: uppercase !important;
		font-family: $sans-serif;
	}

	&.visible {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
}

.contactClose {
	position: fixed;
	top: 2rem;
	right: 3rem;
	background: none;
	border: none;
	outline: 0;

	&:hover {
		cursor: pointer;
	}
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
	color: black;
}

.wpcf7-form-control-wrap {
	display: block;
	padding: 0;
	margin-bottom: rem(15);
}

.resume input {
	font-family: arial, sans-serif;
}
