/*************************
HOMEPAGE SPECIFIC STYLES
*************************/

.home {
	.main-content {
		display: block;
	}

	.mainContent {
		padding-top: 0;
	}

	.navWrapper__open {
		background-color: color(black);
		svg path {
			fill: color(green);
		}

		&:hover,
		&:focus {
			background-color: color(green);
			svg path {
				fill: color(black);
			}
		}
	}

	.header {
		background-color: transparent;

		&__logo {
			visibility: hidden;
			opacity: 0;
			transition: 0.4s all ease-in-out;
		}

		&__socialNav {
			visibility: hidden;
			opacity: 0;
			transition: 0.2s all ease-in-out;

			svg path {
				fill: color(green);
			}
		}

		&.reveal {
			background-color: color(green);

			.header__logo {
				opacity: 1;
				visibility: visible;
			}

			.navWrapper__open {
				background-color: color(green);
				transition: 0.2s all ease-in-out;

				svg path {
					fill: color(black);
				}

				&:hover,
				&:focus {
					background-color: color(black);
					svg path {
						fill: color(green);
					}
				}
			}

			.header__socialNav {
				visibility: visible;
				opacity: 1;

				svg path {
					fill: color(black);
				}
			}
		}
	}
}

.hero {
	position: relative;

	&__image {
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100vw;
		display: block;
		animation-name: taco;
		animation-duration: 7s;
		animation-delay: 3s;
		animation-direction: normal;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		background-position: center;
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide1.jpg");
		transition: 0.2s all ease-in-out;
		will-change: transform;
		transform: translateZ(0);
	}

	&__content {
		position: absolute;
		bottom: 4vw;
		left: 50%;
		transform: translateX(-50%);
		mix-blend-mode: difference;

		@include min(md) {
			bottom: 0;
		}

		svg {
			width: 75vw;
			height: auto;
		}
	}
}

.home-callouts {
	background-color: color(green--dark);
	text-align: center;
	padding-bottom: rem(20);
	position: relative;

	@include min(md) {
		padding-bottom: rem(40);
	}

	&--desktop {
		@include max(md) {
			display: none;
		}
	}

	&--mobile {
		@include min(md) {
			display: none;
		}
	}

	&--desktop,
	&--mobile {
		> svg {
			width: 95vw;
			height: auto;
			position: relative;
			z-index: 2;
			margin-top: -5vw;

			a {
				&:hover,
				&:focus {
					path,
					text {
						fill: white;
					}
					rect {
						stroke: white;
					}
				}
			}
		}
	}
}

.homeCarousel {
	position: absolute;
	width: 90vw;
	height: auto;
	overflow: hidden;
	top: 39vw;
	left: 5vw;

	@include min(md) {
		top: 13vw;
	}

	&.glide .glide__slides {
		border: none;
	}
}

// Rewards
.rewards {
	background-image: url(/wp-content/themes/tacoria/public/images/menu-bg.jpg);
	background-color: black;
	color: white;
	padding-block: 5rem;
	text-align: center;

	&__heading {
		@include min(md) {
			font-size: 3rem;
		}
	}

	&__content {
		max-width: 700px;
		margin: 0 auto;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: center;

		@include min(sm) {
			flex-direction: row;
		}

		a {
			img {
				width: 200px;
				height: auto;
				border: 2px solid color(green--dark);
				border-radius: 6px;
				transition: 0.2s all ease-in-out;
			}

			&:hover,
			&:focus {
				img {
					opacity: 0.8;
				}
			}
		}
	}
}
