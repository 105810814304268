.error404 {
	.mainContent {
		min-height: 90vh;
		display: flex;
		text-align: center;
		align-items: center;
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-image: url(/wp-content/themes/tacoria/public/images/menu-bg.svg);
			background-attachment: fixed;
			background-position: center;
			background-size: cover;
			opacity: 0.15;
			z-index: 1;
		}
	}

	.wrapMd {
		position: relative;
		z-index: 2;
	}
}
