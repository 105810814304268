.page-template-page-inspiration {
	.mainContent {
		@include responsivebackground(
			"inspiration-bg--sm.jpg",
			"inspiration-bg--md.jpg",
			"inspiration-bg--lg.jpg"
		);
		position: relative;
		background-repeat: repeat-y;
		background-size: 100% auto;
		background-attachment: fixed;
		overflow: hidden;
	}
}

.inspirationSection {
	width: 100%;
	padding-top: rem(40);
	padding-bottom: rem(40);
	margin-top: 10vw;
	margin-bottom: 10vw;
	position: relative;
	z-index: 2;

	.inspirationImage {
		position: absolute;

		@include max(md) {
			display: none;
		}

		&-avacado {
			left: 70vw;
			top: rem(-200);
		}

		&-salsa {
			right: 85vw;
			top: rem(200);

			@media screen and (min-width: 1120px) {
				right: calc(800px + ((100% - 750px) / 2));
			}
		}

		&-limes {
			left: 85vw;
			top: rem(100);

			@include min(xl) {
				left: 80vw;
			}
		}
	}

	.contentContainer {
		width: 80vw;
		margin-left: auto;
		margin-right: auto;
		max-width: rem(800);

		@include min(md) {
			width: 70vw;
		}
	}

	&1 {
		background-color: rgba(0, 0, 0, 0.7);

		h2 {
			margin-top: 0;

			@include min(md) {
				font-size: rem(40);
			}
		}

		p {
			color: white;
			font-size: rem(20);
		}
	}

	&2 {
		background-color: color(green);
		color: black;

		p {
			font-size: rem(24);
		}
	}
}

.inspiration-bg-photos {
	position: absolute;
	top: rem(-100);
	z-index: 1;
}
