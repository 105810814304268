/*********************
GENERAL STYLES
*********************/

// Universal Box Sizing with Inheritance
html {
	box-sizing: border-box;
	font-size: 100%; // 16px
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

::selection {
	background: color(green--dark);
	color: color(green);
}
::-moz-selection {
	background: color(green--dark);
	color: color(green);
}

body {
	font-family: $sans-serif;
	line-height: 1.5;
	color: $text-color;
	background-color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	text-transform: uppercase;
}

.noScroll {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

:focus {
	outline: 3px dotted gray;
}

// Screen Reader Text
.srOnly {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}
