.page-template-page-careers {
	.pageContent {
		background-size: cover;
		background-position: center;
		@include responsivebackground(
			"careers-bg--sm.jpg",
			"careers-bg--md.jpg",
			"careers-bg--lg.jpg"
		);
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex: 1;

		@include max(md) {
			padding-left: 5%;
			padding-right: 5%;
		}
	}

	.form--modal {
		form {
			margin-top: 0;
		}
	}
}

.careerForm {
	background-color: color(green);
	color: black;
	@include sectionspace();

	&__heading {
		text-align: center;
	}

	&__container {
		max-width: rem(800);
		margin: 0 auto;
	}

	.gf_progressbar_title {
		color: color(black) !important;
	}
}

.careerTrigger {
	visibility: hidden;
	opacity: 0;
	transition: 0.4s all ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
}
