.woocommerce {
    input[type],
    select,
    textarea,
    .field {
        background-color: white;
        border: none;
    }
}

.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt,
.woocommerce #respond input#submit, 
.woocommerce a.button, 
.woocommerce button.button, 
.woocommerce input.button {
    background-color: color(green) !important;
    color: black !important;
    border: none;
}

.woocommerce th, 
.woocommerce td {
    background-color: black !important;
    color: white !important;

    a {
        color: color(green) !important;
    }
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
    color: black;
}

.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).button-variable-item {
    color: black;

    &.selected {
        background-color: color(green);
    }
}

.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected, 
#add_payment_method #payment, 
.woocommerce-cart #payment, 
.woocommerce-checkout #payment {
    background-color: black;
}

table.variations, table.variations td, table.variations th {
    background-color: black !important;
}

.single-product .woocommerce div.product {
    padding: 0;
}

.woocommerce,
.woocommerce-account,
.woocommerce-cart,
.woocommerce-checkout {
    .pageContent__main {
        padding: 1% 3%;
    }
}

.woocommerce-info a {
    color: color(green--dark);
}

body.woocommerce {
    .pageContent__main {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include min(md) {
            flex-direction: row;
        }

        .woocommerce {
            flex: 1;
            order: 1;

            @include min(md) {
                order: 2;
            }
        }

        .Categories {
            order: 2;
            padding-right: 2rem;

            @include min(md) {
                order: 1;
            }
        }
    }

    .products {
        .add_to_cart_button {
            margin-bottom: 1rem;
        }
    }
}

