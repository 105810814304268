// --- Sizes ---
// Browser Context (this is needed for the px to em's mixin)
$browser-context: 16; // Default

// --- Responsive Breakpoints ---
$breakpoints: (
	sm: 481px,
	md: 768px,
	lg: 1040px,
	xl: 1240px,
	wrap: 1440px
);

// --- Colors ---
$colors: (
	green: #acd8bd,
	green--dark: #386572,
	black: black
);

$z: (
	sectionBg: 1,
	sectionContent: 2
);

/// Text Color
$text-color: map-get($colors, green);
/// Link Color
$link-color: map-get($colors, green);
/// Link Hover
$link-hover: darken($link-color, 15%);

// --- Typography ---
/// Fonts
$serif: "spicy_mexican_street_cornn_Rg", sans-serif;
$sans-serif: "tacoria_regular", serif;

// --- Images ---
// Default Header Hero Image
$hero-sm: "hero-background-sm.jpg"; // width should be 800px
$hero-md: "hero-background-md.jpg"; // width should be 1200px
$hero-lg: "hero-background-lg.jpg"; // width should be > 1200px
