.page-template-page-order {
	.mainContent {
		min-height: 90vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex: 1;
		background-size: cover;
		background-position: center;
		@include responsivebackground(
			"order-bg--sm.jpg",
			"order-bg--md.jpg",
			"order-bg.jpg"
		);
	}
	.pageContent {
		@include max(md) {
			padding-left: 5%;
			padding-right: 5%;
		}
	}
}

.orderTrigger {
	visibility: hidden;
	opacity: 0;
	transition: 0.4s all ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
}

.orderMenu {
	z-index: 6;
	background-color: rgba(0, 0, 0, 0.5);

	&.visible {
		z-index: 6;
	}

	.formClose {
		left: 50%;
		top: 1em;
		transform: translate(-50%, 0);

		@include min(md) {
			transform: none;
			top: 1em;
			right: 1em;
			left: inherit;
		}
	}

	a {
		&:hover {
			text {
				fill: white;
			}
		}
	}

	svg {
		width: 90%;
		height: auto;
	}

	&__menu {
		background-size: 100% 100%;
		position: relative;
		padding: 100px 50px 25px;

		@include min(md) {
			padding: 100px 125px;
			background-image: url(../images/order-menu.svg);
		}

		li {
			list-style: none;
			font-size: 1.5em;

			@include min(md) {
				font-size: 2.5em;
			}
		}
	}

	&__list {
		padding-left: 0;

		a {
			text-decoration: none;
			padding: 0.25em 0;
			display: block;

			@include min(md) {
				padding: 0;
			}
		}
	}
}
