/*********************
UTILITY CLASSES
*********************/

/* For each color create utility classes */
@each $name, $value in $colors {
	.u-bg--#{$name} {
		background-color: $value;
	}
	.u-text--#{$name} {
		color: $value;
	}
	a.u-text--#{$name}:hover {
		color: darken($value, 10%);
	}
}
