.footer {
	background-color: black;
	color: color(white);
	text-align: center;
	border-top: 6px solid color(green--dark);
	padding-bottom: 5em;

	@include min(md) {
		text-align: left;
	}

	input:not([type="checkbox"]):not([type="radio"]),
	select,
	textarea,
	.field {
		border-color: white;
	}

	.fixedButton {
		white-space: nowrap;
		padding-inline: 3em;
		border-radius: 2em;
		z-index: 100;
		box-shadow: 0 2px 16px 10px rgba(0, 0, 0, 0.2);
		position: fixed;
		bottom: 1em;
		left: 50%;
		transform: translateX(-50%);
		font-size: 1em;
		width: 90%;
		text-align: center;

		@include min(md) {
			font-size: 1.5em;
			width: auto;
		}
	}

	a {
		color: color(green);
		font-size: rem(22);

		&:hover,
		&:focus {
			color: white;
		}
	}

	&__form {
		@include min(lg) {
			display: flex;
		}
	}

	.gform_wrapper {
		form {
			@include min(md) {
				display: flex;
				align-items: center;
			}

			input[type="text"].large {
				padding: 1em 0.5em;
				line-height: 2.25rem;
				margin-bottom: 0;
				color: white;
				background-color: black;
				border-color: white;

				@include min(lg) {
					min-width: rem(300);
				}
			}

			input[type="submit"] {
				font-size: rem(16);
				box-shadow: none;
				background-color: black;
			}

			.gform_body {
				flex: 2;
			}

			.gform_footer {
				margin: 0;
				flex: 1;
			}
		}

		ul.gform_fields li.gfield {
			padding-right: 0;
		}
	}

	.gform_validation_error {
		form {
			@include min(md) {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	}

	.gform_wrapper li.gfield.gfield_error,
	.gform_wrapper
		li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
		background-color: white;
	}

	.gform_wrapper div.validation_error {
		min-width: 100%;
		flex: 0 1 100%;
	}

	body .gform_wrapper .top_label div.ginput_container.ginput_container_email {
		margin-top: 0 !important;
	}

	input {
		// background-color: white;
		border: 2px solid color(white);
		font-family: $sans-serif;
		letter-spacing: rem(6);
		text-transform: uppercase;
		color: color(green--dark);
		margin-bottom: rem(10);
		text-align: center;
		max-width: rem(400);
		margin-left: auto;
		margin-right: auto;

		@include min(lg) {
			&[type="text"] {
				min-width: rem(300);
			}
		}
	}
	input[type="submit"] {
		border: 2px solid color(green);
		text-transform: uppercase;
		margin-top: 8px;
	}
}

.footer__menu {
	&.two-cols {
		columns: 2;
		column-gap: 3em;
	}

	li {
		display: block;

		a {
			text-decoration: none;
		}
	}

	.dialog__open {
		background-color: transparent;
		color: color(green);
		border: none;
		padding: 0;
		font-size: 1.375rem;
		font-family: $sans-serif;
		text-transform: uppercase;
		line-height: 1.5;
	}
}

.footerRow {
	@include min(md) {
		display: flex;
		width: 100%;
		padding-bottom: rem(20);
	}

	> * {
		@include min(md) {
			padding-right: rem(30);
		}
	}

	&--one {
		justify-content: space-between;
	}

	&--two {
		justify-content: space-between;
		align-items: center;
	}

	.subscribe {
		@include min(md) {
			flex: 0 1 rem(500);
		}

		input[type="text"] {
			border: 1px solid white !important;
		}
	}
}
