/******************************************************************
Form Styles
******************************************************************/

// setting up defaults
input:not([type="checkbox"]):not([type="radio"]),
select,
textarea,
.field {
	display: block;
	line-height: 1em;
	font-size: 1em;
	color: black;
	border-color: color(black);
	font-family: sans-serif !important;
	border-width: 4px;
	border-style: dotted;
	border-spacing: inherit;
	border-radius: 0;
	vertical-align: middle;
	box-shadow: none;
	width: 100%;
	background-color: transparent;
	transition: background-color 0.24s ease-in-out;
	padding: 0.5em;
	box-sizing: border-box;

	// a focused input (or hovered on)
	&:focus,
	&:active {
		background-color: white;
		outline: none;
	} // end hover or focus

	// error notification
	&.error,
	&.is-invalid {
		color: red;
		border-color: red;
		background-color: white;
		outline-color: red;
	} // end invalid input
} // end input defaults

// textareas
textarea {
	max-width: 100%;
	min-height: 120px;
	line-height: 1.5em;
	text-transform: uppercase;
	font-family: $serif;
}

// submit button
input[type="submit"] {
	// class added to override styling above
	@include button();
	width: 100%;
	appearance: none;
	background-image: none;

	&:focus {
		outline: 3px dotted gray;
	}
}

// Modal Form
.form--modal {
	visibility: hidden;
	// transform: translateY(-100vh);
	opacity: 0;
	transition: 0.4s all ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.85);
	overflow-y: auto;

	@include min(md) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.location {
		margin-bottom: rem(15);
	}

	.form__container {
		position: relative;
	}

	form {
		background: color(green);
		padding: rem(30);
		width: 90%;
		max-width: rem(600);
		margin-left: auto;
		margin-top: rem(40);
		margin-right: auto;
		position: relative;
		color: black;
		text-transform: uppercase;
		font-family: $sans-serif;
		text-align: left;

		@include min(md) {
			min-width: rem(400);
		}
	}

	label {
		margin-top: rem(20);
	}

	p {
		margin: 0;
	}

	input {
		border: none;
		border-bottom: 4px dotted black;
	}

	input[type="submit"] {
		border-bottom: none;
		box-shadow: none;
		font-size: rem(24);
		background-color: black;
		background-image: none;
		appearance: none;
	}

	select,
	input {
		color: black;
		text-transform: uppercase !important;
		font-family: $sans-serif;

		option {
			color: black;
		}
	}

	&.visible {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
}

.formClose {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(30%, -30%);
	background: none;
	border: none;
	outline: 0;
	z-index: 3;

	&:hover {
		cursor: pointer;
	}
}

div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
	color: black;
}

.wpcf7-form-control-wrap {
	display: block;
	padding: 0;
	margin-bottom: rem(15);
}

.resume input {
	font-family: arial, sans-serif;
}

.wpcf7-response-output {
	margin-top: rem(20);

	&.wpcf7-validation-errors {
		color: darkred;
	}
}

.wpcf7-not-valid-tip {
	color: darkred;
}

/* Text meant only for screen readers. */
.screen-reader-response {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.gform_wrapper {
	.top_label {
		input[type="file"].medium {
			width: 100%;
		}
	}
}

.gform_wrapper .gform_footer {
	text-align: center;
}

.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper
	.field_sublabel_below
	div[class*="gfield_time_"].ginput_container
	label {
	margin-bottom: 0;
}

.hidden {
	display: none;
}

.gform_confirmation_message {
	background-color: color(green);
	color: black;
	padding: rem(40);
}

.ginput_container_multiselect {
	select {
		color: black;
	}
}

.gform_next_button,
.gform_previous_button {
	color: black !important;
	font-weight: bold;
	&:hover,
	&:focus {
		color: color(green) !important;
		background-color: black !important;
	}
}

.gform_confirmation_message,
.ginput_container_radio {
	a {
		color: black;
	}
}
