.skipLink {
	position: absolute;
	left: 6px;
	top: -99px;
}

.skipLink:focus {
	z-index: 999;
	font-size: 14px;
	font-weight: 700;
	top: 6px;
	padding: 15px 23px 14px;
	background-color: color(green--dark);
	color: white !important;
}
