/*********************
LINK STYLES
*********************/

a,
a:visited {
	color: $link-color;

	/* on hover */
	&:hover,
	&:focus {
		color: $link-hover;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
	}
}
