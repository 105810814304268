@keyframes taco {
	25% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide1.jpg");
	}
	30% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide2.jpg");
	}
	35% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide3.jpg");
	}
	40% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide4.jpg");
	}
	45% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide5.jpg");
	}
	50% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide6.jpg");
	}
	55% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide7.jpg");
	}
	60% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide8.jpg");
	}
	90% {
		background-image: url("/wp-content/themes/tacoria/public/images/taco-slide8.jpg");
	}
}
