/********************
LAYOUT & GRID STYLES
*********************/

[class*="wrap"] {
	margin-left: auto;
	margin-right: auto;
	padding-left: rem(15);
	padding-right: rem(15);

	@include min(md) {
		padding-left: rem(20);
		padding-right: rem(20);
	}
}

// anchor footer to bottom on short pages
#container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	min-height: 100vh;
}

#container > footer {
	margin-top: auto;
}

.mainContent {
	padding-top: rem(75);
}

.wrapper {
	margin-left: 5vw;
	margin-right: 5vw;
	width: 90vw;
}
