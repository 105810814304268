/*********************
NAVIGATION STYLES
*********************/

.navWrapper__open {
	border: none;
	padding: rem(10);
	border-radius: 0;
	cursor: pointer;
	background-color: transparent;

	&:hover,
	&:focus {
		background-color: color(black);
		svg path {
			fill: color(green);
		}
	}

	@include max(md) {
		order: 2;
	}
}

.navWrapper__open:hover,
.navWrapper__open:hover:focus {
}

.navWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	min-height: 100vh;
	overflow: scroll;
	background: black;
	transition: 0.2s all ease-in-out;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	overflow-x: hidden;
	background-image: url(/wp-content/themes/tacoria/public/images/menu-bg.jpg);

	&.visible {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
	}

	&__close {
		cursor: pointer;
		color: color(green);
		font-size: 1.2rem;
		font-weight: 700;
		// padding: 1rem;
		border: none;
		background-color: transparent;
	}

	&__socialNav {
		padding-top: rem(20);
		padding-bottom: 10%;

		@include min(md) {
			padding-bottom: 0;
		}

		svg path {
			fill: color(green);
		}

		a:hover {
			svg path {
				fill: white;
			}
		}
	}
}

.nav {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		list-style: none;
		display: inline-block;

		a {
			display: inline-block;
		}
	} /* end .menu li */
} /* end .nav */

.siteNav {
	li {
		display: block;

		a {
			font-family: $serif;
			display: block;
			color: color(green);
			font-size: rem(24);
			text-decoration: none;

			&:hover {
				color: white;
			}

			@include min(md) {
				font-size: rem(40);
			}

			@media screen and (max-height: 900px) {
				font-size: rem(40);
			}

			@media screen and (max-height: 730px) {
				font-size: rem(30);
			}
		}
	}

	.nav-order {
		@include min(md) {
			display: none;
		}
	}

	.hit-us-up {
		a {
			font-family: $sans-serif;
		}
	}

	.my-account {
		a {
			font-size: 1.5rem;
		}
	}
}

.navContainer {
	height: 100%;
	width: 100%;
	padding: 10% 5%;
	position: relative;
	z-index: 5;
	// background-image: url(/wp-content/themes/tacoria/public/images/line-horizontal.svg),
	// 	url(/wp-content/themes/tacoria/public/images/line-vertical.svg),
	// 	url(/wp-content/themes/tacoria/public/images/line-horizontal.svg),
	// 	url(/wp-content/themes/tacoria/public/images/line-vertical.svg);
	// background-position: rem(20) rem(20), calc(100% - 20px) rem(20),
	// 	calc(100% - 20px) calc(100% - 20px), rem(20) calc(100% - 20px);
	// background-size: calc(100% - 40px) auto, auto calc(100% - 40px),
	// 	calc(100% - 40px) auto, auto calc(100% - 40px);
	// background-repeat: no-repeat;

	@include min(md) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.order-here {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(rem(10), -50%);
	transition: 0.2s all ease-in-out;

	path {
		transition: 0.2s all ease-in-out;
	}

	&:hover {
		transform: translate(0, -50%);

		#banner path {
			fill: white;
		}
	}

	@include max(md) {
		display: none;
	}
}

.navLocations {
	background-color: color(green--dark);
	padding-top: rem(8);
	padding-bottom: rem(8);
	margin: 0;

	a {
		color: color(white);
		text-decoration: none;
		padding-left: rem(10);
		padding-right: rem(10);
		transition: 0.2s all ease-in-out;
		white-space: nowrap;

		&:hover {
			opacity: 0.6;
		}

		@include min(md) {
			font-size: rem(24);
			padding-left: rem(20);
			padding-right: rem(20);
		}
	}

	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
}
