.page-template-page-locations {
	background-color: color(green);
	color: color(green--dark);
}

.location {
	color: black;
	margin-bottom: 5vw;

	&FeaturedImage {
		img {
			width: 100%;
			height: auto;
		}
	}

	h2,
	h2 a {
		color: color(green);
		font-family: $sans-serif;
		font-size: rem(40);

		@include min(lg) {
			font-size: rem(60);
		}
	}
}

.locationCarousel {
	border-top: 20px solid rgba(56, 101, 114, 0.64);
	border-bottom: 20px solid rgba(56, 101, 114, 0.64);
	border-left: 20px solid rgba(56, 101, 114, 0.64);
	border-right: 20px solid rgba(56, 101, 114, 0.64);

	.glide__slides {
		border: none;
	}
}

.locationInfo {
	margin-left: 5vw;
	margin-right: 5vw;
	width: 90vw;

	@include min(lg) {
		display: flex;
	}

	a {
		color: color(green--dark);
		text-decoration: none;
	}

	p {
		margin: 0;
	}

	&__left,
	&__right {
		@include min(lg) {
			flex: 1;
			min-width: 50vw;
			width: 50vw;
		}
	}

	&__left {
		margin-top: rem(-150);

		@include min(lg) {
			margin-top: rem(-250);
		}
	}

	&__right {
		padding-left: rem(20);
		padding-top: rem(20);
	}
}

.locationAddress {
	font-family: $serif;
	font-size: rem(22);

	@include min(lg) {
		font-size: rem(36);
	}

	span {
		display: block;
	}
}

.locationPhone {
	font-size: rem(40);

	@include min(lg) {
		font-size: rem(50);
	}
}

.locationHours {
	font-size: rem(24);

	@include min(lg) {
		font-size: rem(34);
	}
}

.locationLinks {
	&__left,
	&__right {
		display: flex;
		margin-top: rem(10);
		margin-bottom: rem(10);
		align-items: center;

		a {
			margin-right: rem(20);
			transition: 0.2s all ease-in-out;
			color: black;

			&:hover,
			&:focus {
				text-decoration: underline;
				color: black;
			}

			@include min(lg) {
				font-size: rem(22);
			}

			&:last-child {
				margin-right: 0;
			}
		}

		svg {
			max-height: 40px;
			max-width: 150px;
		}
	}
}

.locationReviews {
	padding-block: rem(48);
}
