/*********************
HEADER STYLES
*********************/

.header {
	background-color: color(green);
	color: black;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	will-change: transform;
	transition: transform 200ms linear;
	width: 100%;

	&.headroom--pinned {
		transform: translateY(0%);

		&.headroom--not-top {
			position: fixed;
			top: 0;
			left: 0;
		}
	}
	&.headroom--unpinned {
		transform: translateY(-100%);
	}

	h1 {
		margin: 0;
	}

	* {
		color: black;
	}

	&__logo {
		@include max(md) {
			order: 1;
			max-width: rem(120);
		}
		svg {
			transition: 0.2s all ease-in-out;
			height: rem(47);
			width: rem(130);

			@include max(md) {
				svg {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	&Wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: rem(10);
		padding-bottom: rem(10);
	}

	&__socialNav {
		@include max(md) {
			display: none;
		}
	}
}

.socialNav {
	.nav {
		li:first-of-type {
			a {
				padding-left: 0;
			}
		}
	}
	a {
		padding-left: rem(10);

		&:hover,
		&:focus {
			svg path {
				fill: color(white);
			}
		}
	}
}

.header__socialNav {
	a {
		&:hover,
		&:focus {
			svg path {
				fill: color(black);
			}
		}
	}
}
